
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import UploadFile from '@/components/uploadFile/Index.vue'
// import { oneDecimal } from '@/utils/validate'
const oneDecimal = (rule: object, value: any, callback: Function) => {
  const reg = /^([1-9][0-9]*|[0-9])(\.\d)?$/
  if (value && !reg.test(value)) {
    return callback(new Error('正整数或1位小数'))
  } else if (value < 0.1 || value > 24) {
    return callback(new Error('0.1到24之间'))
  } else {
    return callback()
  }
}

const oneDecimal2 = (rule: object, value: any, callback: Function) => {
  const reg = /^([1-9][0-9]*|[0-9])(\.\d)?$/
  if (value && !reg.test(value)) {
    return callback(new Error('正整数或1位小数'))
  } else if (value < 0.1 || value > 999.9) {
    return callback(new Error('0.1到999.9之间'))
  } else {
    return callback()
  }
}

@Component({
  name: 'AddTaiBanLabor',
  components: { UploadFile },
  filters: {
    imgListFormat (list: any) {
      const imgTypeList = ['jpeg', 'jpg', 'png']
      const imgList: Array<string> = []
      list.forEach((item: any) => {
        const str = item.fileName.split('.')
        if (imgTypeList.find((items) => items === str[str.length - 1].toLowerCase())) {
          imgList.push(item.filePrefix + item.fileUrl)
        }
      })
      return imgList || []
    }
  }
})
export default class extends Vue {
  @Prop() readonly addShow!: boolean
  @Prop() readonly id!: string

  private pickerOptions={
    disabledDate (time: any) {
      const now = (new Date()).getTime()
      const sevenAfter = 91 * 24 * 3600 * 1000
      const res = now - sevenAfter
      // return time.getTime() < Date.now() - 8.64e7 || time.getTime() > res

      return time.getTime() > (new Date()).getTime() || time.getTime() < res
    }
  }

  private info = {
    // 基础信息
    projectId: '',
    machineTeamDate: '',
    machineTeamLines: [],
    headRemark: '',
    fileList: []
  } as any

  private operateIndex = 'unOperated' as any // 当前操作行的索引，等于 unOperated 表示未操作，可以进行新增和编辑
  private machineryData = [] as any
  private specificationsData = [] as any
  private supplierData = [] as any
  private submitShow = false

  private rules= {
    projectId: [
      { required: true, message: '请选择所属项目', trigger: 'change' }
    ],
    machineTeamDate: [
      { required: true, message: '请选择台班日期', trigger: 'change' }
    ],
    machineTeamLines: [
      { required: true, message: '请输入台班机械', trigger: 'change' }
    ],
    fileList: [
      { required: true, message: '请选择现场图片', trigger: 'change' }
    ],
    machineryName: [
      { required: true, message: '请选择机械名称', trigger: 'change' }
    ],
    specificationModel: [
      { required: true, message: '请选择规格型号', trigger: 'change' }
    ],
    // supplierId: [
    //   { required: true, message: '请选择供应商名称', trigger: 'change' }
    // ],
    jobContent: [
      { required: true, message: '请输入作业内容', trigger: 'change' }
    ],
    hours: [
      { required: true, message: '请输入台班', trigger: 'change' },
      { validator: oneDecimal, trigger: 'change' }
    ],
    price: [
      { required: true, message: '请输入单价', trigger: 'change' },
      { validator: oneDecimal2, trigger: 'change' }
    ]
  }

  get projectList () {
    return this.$store.state.projectList
  }

  @Watch('id')
  isTrue (val: string) {
    val && this.getDetail()
  }

  created () {
    this.loadMachineryData() // 获取机械
  }

  // 获取机械
  loadMachineryData () {
    this.$axios.get(this.$apis.laborManage.selectYhMachineryByList).then(res => {
      this.machineryData = this.resloveData([], 'machineryName', res)
    })
  }

  getDetail () {
    this.$axios.get(this.$apis.laborManage.selectYhMachineTeamByMachineTeamId, { machineTeamId: this.id }).then(res => {
      if (res) {
        res.machineTeamLines.forEach((item: any) => {
          item.isFirstAllChoose = true
        })
        this.info = res
      }
    })
  }

  hoursChange (val: any, row: any) {
    if (/^([1-9][0-9]*|[0-9])(\.\d)?$/.test(val)) {
      row.teamPrice = val * row.price
    }
  }

  getSummaries (param: any) {
    const { data } = param
    let total = 0
    data.forEach((item: any) => {
      if (/^([1-9][0-9]*|[0-9])(\.\d)?$/.test(item.teamPrice)) {
        total += parseFloat(item.teamPrice)
      }
    })
    return ['合计', '', '', '', '', '', '', total]
  }

  onAdd () {
    // console.log('新增', this.operateIndex)
    if (this.operateIndex !== 'unOperated') {
      this.$message.warning('请先保存！')
      return
    }
    this.info.machineTeamLines.push(
      {
        machineryId: '',
        machineryName: '',
        specificationModel: '',
        supplierId: '',
        jobContent: '',
        teamPrice: '',
        hours: '',
        price: '',
        lineRemark: '',
        isEdit: true,
        isFirstAllChoose: false // 是否选择机械、供应商、规格
      }
    )
    this.operateIndex = this.info.machineTeamLines.length - 1
  }

  onEdit (row: any, index: any) {
    if (this.operateIndex !== 'unOperated') {
      this.$message.warning('请先保存！')
      return
    }
    // console.log('编辑', row, index)
    // 刷新表格
    row.isEdit = true
    this.info.machineTeamLines.splice(index, 1)
    this.$nextTick(() => {
      this.info.machineTeamLines.splice(index, 0, row)
      this.operateIndex = index
    })

    // 重置规格、供应商
    this.$axios.get(this.$apis.laborManage.selectYhMachineryByList, {
      machineryName: row.machineryName
    }).then(res => {
      this.specificationsData = this.resloveData(this.specificationsData, 'specificationModel', res)
      this.supplierData = this.resloveData(this.supplierData, 'supplierId', res)
    })
  }

  onDelete (row: any, index: any) {
    // console.log('删除', row, index)
    this.info.machineTeamLines.splice(index, 1)
    if (index === this.operateIndex) {
      this.operateIndex = 'unOperated'
    } else if (index < this.operateIndex) {
      this.operateIndex = this.operateIndex - 1
    }
  }

  // 选择机械名称
  selectmachineryName (row: any) {
    this.$axios.get(this.$apis.laborManage.selectYhMachineryByList, {
      machineryName: row.machineryName
    }).then(res => {
      if (res.length === 1) {
        console.log('res', res[0])
        row.machineryId = res[0].machineryId
        row.specificationModel = res[0].specificationModel
        row.supplierId = res[0].supplierId
        row.supplierName = res[0].supplierName
        row.whetherOil = res[0].whetherOil
        row.isFirstAllChoose = true
        row.price = res[0].unitPrice
      } else {
        row.specificationModel = ''
        row.supplierId = ''
        row.whetherOil = ''
        row.isFirstAllChoose = false
      }
      // 重置规格、供应商并且去重
      this.specificationsData = this.resloveData(this.specificationsData, 'specificationModel', res)
      this.supplierData = this.resloveData(this.supplierData, 'supplierId', res)
    })
  }

  // 选择规格型号
  selectSpecificationModel (row: any) {
    if (row.machineryName && row.specificationModel && row.supplierId) {
      if (!row.isFirstAllChoose) {
        this.$axios.get(this.$apis.laborManage.selectYhMachineryByList, {
          machineryName: row.machineryName,
          specificationModel: row.specificationModel,
          supplierId: row.supplierId
        }).then(res => {
          row.machineryId = res[0].machineryId
          row.supplierName = res[0].supplierName
          row.whetherOil = res[0].whetherOil
          row.isFirstAllChoose = true
          this.info.machineTeamLines.splice(this.operateIndex, 1)
          this.$nextTick(() => {
            this.info.machineTeamLines.splice(this.operateIndex, 0, row)
          })
        })
      } else {
        row.machineryId = ''
        this.supplierData = []
        row.supplierId = ''
        row.whetherOil = ''
        row.isFirstAllChoose = false
        this.resetSupplier({
          machineryName: row.machineryName,
          specificationModel: row.specificationModel
        })
      }
    } else {
      this.resetSupplier({
        machineryName: row.machineryName,
        specificationModel: row.specificationModel
      })
    }
  }

  // 选择供应商
  selectSupplierName (row: any) {
    if (row.machineryName && row.specificationModel && row.supplierId) {
      if (!row.isFirstAllChoose) {
        this.$axios.get(this.$apis.laborManage.selectYhMachineryByList, {
          machineryName: row.machineryName,
          specificationModel: row.specificationModel,
          supplierId: row.supplierId
        }).then(res => {
          row.machineryId = res[0].machineryId
          row.supplierName = res[0].supplierName
          row.whetherOil = res[0].whetherOil
          row.isFirstAllChoose = true
          // console.log('whetherOil', row.whetherOil)
          // this.$forceUpdate()
          this.info.machineTeamLines.splice(this.operateIndex, 1)
          this.$nextTick(() => {
            this.info.machineTeamLines.splice(this.operateIndex, 0, row)
          })
        })
      } else {
        row.machineryId = ''
        this.specificationsData = []
        row.specificationModel = ''
        row.whetherOil = ''
        row.isFirstAllChoose = false
        this.resetSpecificationModel({
          machineryName: row.machineryName,
          supplierId: row.supplierId
        })
      }
    } else {
      this.resetSpecificationModel({
        machineryName: row.machineryName,
        supplierId: row.supplierId
      })
    }
  }

  // 重置供应商
  resetSupplier (data: any) {
    this.$axios.get(this.$apis.laborManage.selectYhMachineryByList, data).then(res => {
      this.supplierData = this.resloveData(this.supplierData, 'supplierId', res)
    })
  }

  // 重置规格
  resetSpecificationModel (data: any) {
    this.$axios.get(this.$apis.laborManage.selectYhMachineryByList, data).then(res => {
      this.specificationsData = this.resloveData(this.specificationsData, 'specificationModel', res)
    })
  }

  // 去重
  resloveData (data: any, key: any, target: any) {
    // console.log('去重', data, key, target)
    const obj = {} as any
    data = target.reduce((val: any, item: any) => {
      if (!obj[item[key]]) {
        val.push(item)
        obj[item[key]] = true
      }
      return val
    }, [])
    return data
  }

  onSave (row: any, index: any) {
    console.log(row, index)
    if (!row.machineryName) {
      this.$message.warning('请选择机械名称！')
      return
    }
    if (!row.specificationModel) {
      this.$message.warning('请选择规格型号！')
      return
    }
    // if (!row.supplierId) {
    //   this.$message.warning('请选择供应商名称！')
    //   return
    // }
    if (!row.jobContent) {
      this.$message.warning('请输入作业内容！')
      return
    }

    if (!row.hours) {
      this.$message.warning('请输入台班！')
      return
    }
    if (!row.price) {
      this.$message.warning('请输入单价！')
      return
    }
    if (!row.teamPrice) {
      this.$message.warning('请输入台班金额！')
      return
    }
    if (!/^([1-9][0-9]*|[0-9])(\.\d)?$/.test(row.teamPrice)) {
      this.$message.warning('台班金额格式错误！')
      return
    }
    this.info.machineTeamLines[index].isEdit = false
    // 刷新表格
    this.info.machineTeamLines.splice(index, 1)
    this.$nextTick(() => {
      this.info.machineTeamLines.splice(index, 0, row)
    })

    this.operateIndex = 'unOperated'
  }

  saveForm () {
    (this.$refs.formData as ElForm).validate(valid => {
      if (valid) {
        if (this.operateIndex !== 'unOperated') {
          this.$message.warning('请先保存台班机械！')
          return
        }
        this.submitShow = true
        const params = JSON.parse(JSON.stringify(this.info))
        console.log('params', params)
        params.machineTeamLines = params.machineTeamLines.map((item: any) => {
          return {
            machineryId: item.machineryId,
            jobContent: item.jobContent,
            hours: item.hours,
            price: item.price,
            teamPrice: item.teamPrice,
            lineRemark: item.lineRemark
          }
        })
        // console.log('params2', params)
        const urls = this.id ? this.$apis.laborManage.updateYhMachineTeam : this.$apis.laborManage.insertYhMachineTeam
        this.$axios.post(urls, params).then(() => {
          this.$message.success('保存成功')
          this.handleClose()
          this.$emit('success')
          this.$emit('getTodayEvent')
        }).finally(() => {
          this.submitShow = false
        })
      }
    })
  }

  // 文件上传成功返回
  onSuccess (file: any) {
    (this.info as any).fileList.push(file)
  }

  imgRemove (index: number) {
    this.info.fileList.splice(index, 1)
  }

  handleClose () {
    this.$emit('update:addShow', false)
    this.$emit('update:id', '')
    this.operateIndex = 'unOperated'
    this.info = {
    // 基础信息
      projectId: '',
      machineTeamDate: '',
      machineTeamLines: [],
      headRemark: '',
      fileList: []
    };
    (this.$refs.formData as ElForm).resetFields()
  }
}
